import React, { useContext } from 'react'
import { Stack, Main, PreFooter } from '@layout'
import pageContextProvider from '@helpers/pageContextProvider'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'

const Collection = ({ data: { posts, collectionInfo } }) => {

  const context = useContext(pageContextProvider)

  const { pageContext: { services = {}, siteUrl, slug } = {}} = context

  return (
    <>
      <Seo title={collectionInfo.name} siteUrl={siteUrl+slug} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header="Publié par l'auteur" totalCount={posts.totalCount} />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          <AuthorExpanded author={collectionInfo} />
          <Divider />
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
              omitFooter
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
    </>
  )
}

export default Collection
